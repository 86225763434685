
body,
html {
  min-height: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  font-size: 16px;
}
@media screen and (max-height: 800px) {
body,
  html {
    font-size: 15px;
}
}
#app {
  position: fixed;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: none;
}

@font-face {
  font-family: 'AHAMONO';
  src: url("/static/fonts/AHAMONO-Regular.otf");
  /* IE9 Compat Modes */
  src: url("/static/fonts/AHAMONO-Regular.eot?#iefix") format("embedded-opentype"), url("/static/fonts/AHAMONO-Regular.woff2") format("woff2"), url("/static/fonts/AHAMONO-Regular.woff") format("woff");
  /* Pretty Modern Browsers */
}
.wrapper[data-v-7604d454] {
  width: 100%;
  height: 100%;
  background-color: #000;
  font-family: 'AHAMONO';
}
.wrapper .container[data-v-7604d454] {
    width: 100%;
    height: 100%;
    color: white;
    cursor: none;
}
.wrapper .mobileContainer[data-v-7604d454] {
    position: relative;
    color: white;
    width: 100%;
    height: 100%;
    background-image: url("/static/imgs/bg_mobile.jpg");
    background-position: center center;
    background-size: cover;
}
.wrapper .mobileContainer .mobileLogo[data-v-7604d454] {
      padding-top: 5%;
      text-align: center;
      font-size: 0.9em;
      width: 100%;
}
.wrapper .mobileContainer .mobileFooter[data-v-7604d454] {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 0.55em;
      letter-spacing: 0.3em;
}
.wrapper .mobileContainer .mobileFooter .logo[data-v-7604d454] {
        margin-left: -30px;
}
.wrapper .mobileContainer .mobileFooter > div[data-v-7604d454] {
        display: inline-block;
        vertical-align: middle;
}
.wrapper .mobileContainer .mobileLabel[data-v-7604d454] {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      text-transform: uppercase;
      max-width: 65%;
}
.wrapper .mobileContainer .mobileLabel h1[data-v-7604d454] {
        font-size: 2em;
}
.wrapper .mobileContainer .mobileLabel p[data-v-7604d454] {
        font-size: 1.1em;
}
.wrapper .mobileContainer .buttonStats[data-v-7604d454] {
      width: 100%;
      position: absolute;
      top: 75%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      text-transform: uppercase;
      width: auto;
      padding: 10px 20px;
      border: 1px solid white;
      border-radius: 100px;
}
.vignette[data-v-7604d454] {
  pointer-events: none;
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  background: radial-gradient(ellipse at center, transparent 25%, rgba(0, 0, 0, 0.7) 100%);
  display: none;
}

#appContainer[data-v-0aee5c08] {
  width: 100%;
  height: 100%;
  background-color: #000;
}

.overlayContainer[data-v-b85f9434] {
  position: fixed;
  z-index: 9999;
  top: 7.4%;
  right: 4.1%;
  bottom: 7.4%;
  left: 4.1%;
}

.container[data-v-737eb086] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  will-change: background-color;
  -webkit-transition: background-color 0.8s ease-in-out;
  transition: background-color 0.8s ease-in-out;
  background-color: rgba(7, 10, 41, 0);
  font-size: 1em;
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.container[data-v-737eb086] {
      font-size: 0.7em;
}
}
.container.isPaused[data-v-737eb086] {
    background-color: rgba(7, 10, 41, 0.8);
    bottom: 0;
}
.container.isEnded[data-v-737eb086] {
    display: none;
}
.container .pauseButtonRight[data-v-737eb086] {
    position: fixed;
    z-index: 9999;
    right: 4.1%;
    top: 7.1%;
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
}
.container .pauseButtonRight .pause[data-v-737eb086] {
      padding: 0;
}
.container .pauseButtonRight .label[data-v-737eb086] {
      font-size: 1em;
      letter-spacing: 0.3em;
      margin: 12px 0;
}
.container .pauseButtonRight .eicon img[data-v-737eb086] {
      width: 40%;
}
.container div[data-v-737eb086] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: top;
}
.container .points[data-v-737eb086] {
    -webkit-filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
    filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
    position: fixed;
    bottom: 7.4%;
    left: 50%;
    font-size: 2.6em;
    letter-spacing: normal;
    -webkit-transform: translateX(-50%) translateY(25%);
            transform: translateX(-50%) translateY(25%);
    color: rgba(255, 255, 255, 0.1);
}
.container .scoreContainer[data-v-737eb086] {
    position: fixed;
    top: 8.4%;
    left: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 312px;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}
.container .scoreContainer.isPaused .time[data-v-737eb086] {
      padding: 5% 0 0;
}
.container .scoreContainer.isPaused .score .team[data-v-737eb086] {
      padding-top: 45%;
      font-size: 4em;
}
.container .scoreContainer.isPaused .score .number[data-v-737eb086] {
      padding-top: 40%;
      font-size: 10em;
}
.container .scoreContainer .time[data-v-737eb086] {
      visibility: hidden;
      opacity: 0;
      font-size: 2.4em;
      line-height: 1em;
      font-weight: 500;
      letter-spacing: 0.2em;
      text-align: center;
      width: 420px;
      height: 100%;
      -webkit-filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
      filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
      -webkit-transition: all 0.5s cubic-bezier(0.18, 0.03, 0.08, 1);
      transition: all 0.5s cubic-bezier(0.18, 0.03, 0.08, 1);
}
.container .scoreContainer .time .timerNumbers[data-v-737eb086] {
        padding-left: 0.2em;
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        transition: -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.container .scoreContainer .time .timerNumbers.last[data-v-737eb086] {
          -webkit-transform: scale(3);
                  transform: scale(3);
          padding-left: 0;
}
.container .scoreContainer .time .timerNumbers.goldenGoal[data-v-737eb086] {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
          padding-left: 0;
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.container .scoreContainer .time[data-v-737eb086] {
          width: 200px;
}
}
.container .scoreContainer .score[data-v-737eb086] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      visibility: hidden;
      opacity: 0;
      -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
      transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
      transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
      transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
      -webkit-filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
      filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
}
.container .scoreContainer .score.scoreRight[data-v-737eb086] {
        -webkit-transform: translateX(-20px);
                transform: translateX(-20px);
}
.container .scoreContainer .score.scoreLeft[data-v-737eb086] {
        -webkit-transform: translateX(20px);
                transform: translateX(20px);
}
.container .scoreContainer .score .team[data-v-737eb086] {
        padding: 0 40px;
        font-size: 2.5em;
        letter-spacing: 0.1em;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
}
.container .scoreContainer .score .number[data-v-737eb086] {
        font-weight: 400;
        font-size: 8em;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
}
.container .scoreContainer .time[data-v-737eb086] {
      -webkit-transform: scale(0.7);
              transform: scale(0.7);
}
.container .scoreContainer .time.centerVisible[data-v-737eb086] {
        -webkit-transform: scale(1);
                transform: scale(1);
        visibility: visible;
        opacity: 1;
}
.container .scoreContainer .score > div[data-v-737eb086]:not(.team) {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transition: all 0.4s cubic-bezier(0.18, 0.03, 0.08, 1);
      transition: all 0.4s cubic-bezier(0.18, 0.03, 0.08, 1);
}
.container .scoreContainer .score.leftVisible[data-v-737eb086] {
      -webkit-transition: opacity 0.3s cubic-bezier(0.18, 0.03, 0.08, 1);
      transition: opacity 0.3s cubic-bezier(0.18, 0.03, 0.08, 1);
      -webkit-transform: translateX(0);
              transform: translateX(0);
      visibility: visible;
      opacity: 1;
}
.container .scoreContainer .score.leftVisible > div[data-v-737eb086]:not(.team) {
        -webkit-transform: scale(1);
                transform: scale(1);
}
.container .scoreContainer .score.rightVisible[data-v-737eb086] {
      -webkit-transition: opacity 0.3s cubic-bezier(0.18, 0.03, 0.08, 1);
      transition: opacity 0.3s cubic-bezier(0.18, 0.03, 0.08, 1);
      -webkit-transform: translateX(0);
              transform: translateX(0);
      visibility: visible;
      opacity: 1;
}
.container .scoreContainer .score.rightVisible > div[data-v-737eb086]:not(.team) {
        -webkit-transform: scale(1);
                transform: scale(1);
}
.container .buttons[data-v-737eb086] {
    position: fixed;
    visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 7em;
}
.container .buttons .buttonsContainer[data-v-737eb086] {
      -webkit-transition-property: opacity;
      transition-property: opacity;
      -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
      -webkit-transition-delay: 0.4s;
              transition-delay: 0.4s;
      -webkit-transition-timing-function: cubic-bezier(0.18, 0.03, 0.08, 1);
              transition-timing-function: cubic-bezier(0.18, 0.03, 0.08, 1);
      opacity: 0;
}
.container .buttons.isPaused[data-v-737eb086] {
      visibility: visible;
}
.container .buttons.isPaused .buttonsContainer[data-v-737eb086] {
        opacity: 1;
}
.container .buttons.isPaused .buttonsContainer .pauseBtn[data-v-737eb086] {
          margin-top: 0;
}
.container .buttons .buttonsContainer[data-v-737eb086] {
      position: absolute;
      top: 55%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      -webkit-filter: drop-shadow(0 0 6px rgba(246, 38, 92, 0.4));
      filter: drop-shadow(0 0 6px rgba(246, 38, 92, 0.4));
      color: #f6265c;
}
.container .buttons .buttonsContainer[data-v-737eb086]:hover {
        color: rgba(246, 38, 92, 0.3);
}
.container .buttons .buttonsContainer:hover .pauseBtn[data-v-737eb086] {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9);
}
.container .buttons .buttonsContainer .pauseBtn[data-v-737eb086] {
        cursor: pointer;
        font-size: 1.3em;
        -webkit-transition-property: color, -webkit-transform;
        transition-property: color, -webkit-transform;
        transition-property: color, transform;
        transition-property: color, transform, -webkit-transform;
        -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
        -webkit-transition-timing-function: cubic-bezier(0.18, 0.03, 0.08, 1);
                transition-timing-function: cubic-bezier(0.18, 0.03, 0.08, 1);
}
.container .buttons .buttonsContainer .pauseBtn[data-v-737eb086]:hover {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
          color: #f6265c;
}
.fade-fast-enter-active[data-v-737eb086] {
  -webkit-animation-name: fade-fast-in-data-v-737eb086;
          animation-name: fade-fast-in-data-v-737eb086;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.fade-fast-leave-active[data-v-737eb086] {
  -webkit-animation: fade-fast-out-data-v-737eb086;
          animation: fade-fast-out-data-v-737eb086;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
@-webkit-keyframes fade-fast-in-data-v-737eb086 {
0% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    opacity: 0;
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
}
@keyframes fade-fast-in-data-v-737eb086 {
0% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    opacity: 0;
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
}
@-webkit-keyframes fade-fast-out-data-v-737eb086 {
0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
}
}
@keyframes fade-fast-out-data-v-737eb086 {
0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
}
}

.container3D[data-v-6377ffcd] {
  padding: 20px 40px;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  display: inline-block;
  width: auto;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}
.container3D .eicon[data-v-6377ffcd] {
    min-width: 30px;
    min-height: 30px;
    opacity: 0;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-filter: drop-shadow(0 0 6px rgba(246, 38, 92, 0.4));
    filter: drop-shadow(0 0 6px rgba(246, 38, 92, 0.4));
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-filter 0.4s ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-filter 0.4s ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: filter 0.4s ease-in-out, transform 0.5s ease-in-out, opacity 0.3s ease-in-out;
    transition: filter 0.4s ease-in-out, transform 0.5s ease-in-out, opacity 0.3s ease-in-out, -webkit-filter 0.4s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.container3D .elabel[data-v-6377ffcd] {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
    -webkit-filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
    filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-filter 0.4s ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-filter 0.4s ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: filter 0.4s ease-in-out, transform 0.5s ease-in-out, opacity 0.3s ease-in-out;
    transition: filter 0.4s ease-in-out, transform 0.5s ease-in-out, opacity 0.3s ease-in-out, -webkit-filter 0.4s ease-in-out, -webkit-transform 0.5s ease-in-out;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
}
.container3D:hover .eicon[data-v-6377ffcd] {
    -webkit-filter: drop-shadow(0 0 6px rgba(246, 38, 92, 0.8));
    filter: drop-shadow(0 0 6px rgba(246, 38, 92, 0.8));
}
.container3D:hover .elabel[data-v-6377ffcd] {
    -webkit-filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.8));
    filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.8));
}
.container3D.entered .eicon[data-v-6377ffcd] {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}
.container3D.entered .elabel[data-v-6377ffcd] {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}
.container3D.leaving .eicon[data-v-6377ffcd] {
    opacity: 0;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
}
.container3D.leaving .elabel[data-v-6377ffcd] {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}
.container3D div[data-v-6377ffcd] {
    pointer-events: none;
}
.container3D .moving[data-v-6377ffcd] {
    pointer-events: none;
}

.container[data-v-678f0c70] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  will-change: background-color;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  background-color: rgba(7, 10, 41, 0);
  font-size: 1em;
}
@media screen and (max-width: 1536px) and (max-height: 800px) {
.container[data-v-678f0c70] {
      font-size: 0.8em;
}
}
.container.isEnded[data-v-678f0c70] {
    background-color: rgba(7, 10, 41, 0.8);
}
.container.isEnded.isScores[data-v-678f0c70] {
      background-color: #070a29;
}
.container .wrapper[data-v-678f0c70] {
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}
.container .recap[data-v-678f0c70] {
    position: relative;
    width: 100%;
    height: 200px;
    opacity: 0;
    -webkit-transform: scale(3);
            transform: scale(3);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.container .recap.resultVisible[data-v-678f0c70] {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
}
.container .recap > div[data-v-678f0c70] {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      height: 100%;
}
.container .scoreboard[data-v-678f0c70] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.container .scoreboard .versus[data-v-678f0c70] {
      margin: 0 100px;
      text-align: center;
      font-size: 2em;
      font-weight: 500;
      -webkit-filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
      filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
}
.container .scoreboard .versus.vsVisible[data-v-678f0c70] {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
}
@media screen and (max-width: 1536px) and (max-height: 800px) {
.container .scoreboard .versus[data-v-678f0c70] {
          font-size: 1.8em;
}
}
.container .scoreboard .score[data-v-678f0c70] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
      filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
}
.container .scoreboard .score .team[data-v-678f0c70] {
        font-size: 3.7em;
        letter-spacing: 0.18em;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
}
.container .scoreboard .score .number[data-v-678f0c70] {
        font-size: 7em;
        margin: 0 40px;
        -webkit-transition: all 0.6s ease-in-out;
        transition: all 0.6s ease-in-out;
}
.container .scoreboard .score .team[data-v-678f0c70],
      .container .scoreboard .score .number[data-v-678f0c70] {
        opacity: 0;
        -webkit-transform: translateY(-300px) scale(0.7);
                transform: translateY(-300px) scale(0.7);
}
.container .scoreboard .score .team.scoreVisible[data-v-678f0c70],
        .container .scoreboard .score .number.scoreVisible[data-v-678f0c70] {
          opacity: 1;
          -webkit-transform: translateY(0) scale(1);
                  transform: translateY(0) scale(1);
}
.container .info[data-v-678f0c70] {
    margin: 3% 0 0;
    letter-spacing: 0.2em;
    font-size: 0.8em;
    -webkit-filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
    filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.container .info.enterNameVisible[data-v-678f0c70] {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
}
@media screen and (max-width: 1536px) and (max-height: 800px) {
.container .info[data-v-678f0c70] {
        font-size: 1em;
}
}
.container .register[data-v-678f0c70] {
    position: relative;
    opacity: 0;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.container .register.inputVisible[data-v-678f0c70] {
      opacity: 1;
}
.container .register input[type='text'][data-v-678f0c70] {
      margin: 1% 0 0;
      font-size: 16em;
      line-height: 0.5em;
      letter-spacing: 0.2em;
      background: transparent;
      border: 0;
      width: 100%;
      text-align: center;
      color: #f6265c;
      font-family: 'AHAMONO';
      text-transform: uppercase;
}
.container .register input[type='text'][data-v-678f0c70]:focus {
        outline: none;
}
.container .next[data-v-678f0c70] {
    position: absolute;
    top: 50%;
    right: 14%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 0.8em;
    letter-spacing: 0.2em;
}
.container .next .label[data-v-678f0c70] {
      display: block;
      margin: 16px 0 0;
}
@media screen and (max-width: 1536px) and (max-height: 800px) {
.container .next .eicon img[data-v-678f0c70] {
        width: 75%;
}
}
.fade-enter-active[data-v-678f0c70],
.fade-leave-active[data-v-678f0c70] {
  -webkit-transition: all 1.3s;
  transition: all 1.3s;
}
.fade-enter[data-v-678f0c70],
.fade-leave-to[data-v-678f0c70] {
  opacity: 0.999;
}

.container[data-v-2f9d5985] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 1em;
}
@media screen and (max-height: 800px) {
.container[data-v-2f9d5985] {
      font-size: 0.9em;
}
}
.container .wrapper[data-v-2f9d5985] {
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.container .tabContainer[data-v-2f9d5985] {
    width: 72.5%;
    height: 80vh;
}
.container .tabContainer .tabs[data-v-2f9d5985] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
}
.container .tabContainer .tabs .tab[data-v-2f9d5985] {
        position: relative;
        width: 100%;
        opacity: 0;
}
.container .tabContainer .tabs .tab[data-v-2f9d5985]:first-child {
          -webkit-transition: all 1s cubic-bezier(0.41, 0.2, 0.33, 0.94);
          transition: all 1s cubic-bezier(0.41, 0.2, 0.33, 0.94);
          -webkit-transform: translateY(-150%);
                  transform: translateY(-150%);
}
.container .tabContainer .tabs .tab:first-child.active[data-v-2f9d5985] {
            -webkit-transform: translateY(0);
                    transform: translateY(0);
            opacity: 1;
}
.container .tabContainer .tabs .tab[data-v-2f9d5985]:nth-child(2) {
          -webkit-transition: all 0.8s cubic-bezier(0.41, 0.2, 0.33, 0.94) 0.1s;
          transition: all 0.8s cubic-bezier(0.41, 0.2, 0.33, 0.94) 0.1s;
          -webkit-transform: translateY(50%);
                  transform: translateY(50%);
}
.container .tabContainer .tabs .tab:nth-child(2).active[data-v-2f9d5985] {
            -webkit-transform: translateY(-100%);
                    transform: translateY(-100%);
            opacity: 1;
            -webkit-transition: all 0.8s cubic-bezier(0.41, 0.2, 0.33, 0.94);
            transition: all 0.8s cubic-bezier(0.41, 0.2, 0.33, 0.94);
}
.container .tabContainer .tabs .tab .tabChartHeader[data-v-2f9d5985] {
          margin: 5% 2.5% 0 20%;
          width: auto;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          font-size: 0.8em;
          line-height: 3em;
          letter-spacing: 0.25em;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.container .tabContainer .tabs .tab .tabChartHeader > div[data-v-2f9d5985] {
            -webkit-box-flex: 55%;
                -ms-flex: 55% 0 0px;
                    flex: 55% 0 0;
            text-align: left;
            padding-left: 9%;
}
.container .tabContainer .tabs .tab .tabChartHeader > div[data-v-2f9d5985]:first-child, .container .tabContainer .tabs .tab .tabChartHeader > div[data-v-2f9d5985]:last-child {
              padding-left: 0;
              text-align: center;
              -webkit-box-flex: 18%;
                  -ms-flex: 18% 0 0px;
                      flex: 18% 0 0;
}
.container .tabContainer .tabs .tab .tabChartContent[data-v-2f9d5985] {
          position: relative;
          overflow-x: hidden;
          overflow-y: scroll;
          margin: 0 2.5% 0 20%;
          max-height: 65vh;
}
.container .tabContainer .tabs .tab .tabChartContent[data-v-2f9d5985]::-webkit-scrollbar {
            display: none;
            width: 0;
            background: transparent;
}
.container .tabContainer .tabs .tab .tabChartContent[data-v-2f9d5985]::after {
            content: '';
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 65px;
            background: #070a29;
            /* For browsers that do not support gradients */
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(7, 10, 41, 0)), to(#070a29));
            background: linear-gradient(to bottom, rgba(7, 10, 41, 0), #070a29);
            /* Standard syntax (must be last) */
}
.container .tabContainer .tabs .tab .row[data-v-2f9d5985] {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          padding: 18px 0;
          color: rgba(255, 255, 255, 0.3);
          border-top: 1px solid rgba(255, 255, 255, 0.15);
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          margin-bottom: -1px;
}
.container .tabContainer .tabs .tab .row .position[data-v-2f9d5985] {
            -webkit-box-flex: 22.5%;
                -ms-flex: 22.5% 0 0px;
                    flex: 22.5% 0 0;
            font-size: 5em;
            letter-spacing: -0.05em;
}
.container .tabContainer .tabs .tab .row .name[data-v-2f9d5985] {
            -webkit-box-flex: 30%;
                -ms-flex: 30% 0 0px;
                    flex: 30% 0 0;
            font-size: 6em;
            letter-spacing: 0.25em;
            text-transform: uppercase;
}
.container .tabContainer .tabs .tab .row .score[data-v-2f9d5985] {
            -webkit-box-flex: 42.5%;
                -ms-flex: 42.5% 0 0px;
                    flex: 42.5% 0 0;
            padding-right: 5%;
            text-align: right;
}
.container .tabContainer .tabs .tab .row .score .result[data-v-2f9d5985] {
              font-size: 1.3em;
              letter-spacing: 0.1em;
              color: rgba(255, 255, 255, 0.15);
}
.container .tabContainer .tabs .tab .row .score .result strong[data-v-2f9d5985] {
                color: rgba(255, 255, 255, 0.3);
}
.container .tabContainer .tabs .tab .row .score span[data-v-2f9d5985] {
              display: block;
              font-size: 3.8em;
}
.container .tabContainer .tabs .tab .row.active[data-v-2f9d5985] {
            padding: 18px 0;
            color: white;
            background-color: #070a29;
}
.container .tabContainer .tabs .tab .row.active .name[data-v-2f9d5985] {
              color: #f6265c;
}
.container .tabContainer .tabs .tab .row.active .score .result[data-v-2f9d5985] {
              color: rgba(255, 255, 255, 0.3);
}
.container .tabContainer .tabs .tab .row.active .score .result strong[data-v-2f9d5985] {
                color: white;
}
.container .tabContainer .tabs .tab .row.user[data-v-2f9d5985] {
            visibility: hidden;
            position: absolute;
            bottom: 0;
            left: 0;
            max-width: 77.5%;
            margin: 0 2.5% 0 20%;
}
.container .tabContainer .tabs .tab .tabShareContainer[data-v-2f9d5985] {
          margin: 5% 2.5% 0 20%;
          position: relative;
          width: auto;
          height: 65vh;
}
.container .tabContainer .tabs .tab .tabShareContainer img[data-v-2f9d5985] {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 0;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
}
.container .controlsContainer[data-v-2f9d5985] {
    width: 27.5%;
    height: 80vh;
}
.container .controlsContainer .controls[data-v-2f9d5985] {
      padding: 30% 7.5%;
      color: #f6265c;
}
.container .controlsContainer .controls .control[data-v-2f9d5985] {
        text-align: left;
        text-decoration: none;
        display: block;
        font-size: 3.75em;
        margin-top: 2.5%;
        -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
        -webkit-transition-property: color, -webkit-transform;
        transition-property: color, -webkit-transform;
        transition-property: color, transform;
        transition-property: color, transform, -webkit-transform;
        -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
        -webkit-transition-timing-function: cubic-bezier(0.18, 0.03, 0.08, 1);
                transition-timing-function: cubic-bezier(0.18, 0.03, 0.08, 1);
}
.container .controlsContainer .controls .control[data-v-2f9d5985]:hover, .container .controlsContainer .controls .control.active[data-v-2f9d5985] {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
          color: #f6265c;
}
.container .controlsContainer .share[data-v-2f9d5985] {
      padding: 0 7.5%;
      text-align: left;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
}
.container .controlsContainer .share > a[data-v-2f9d5985] {
        position: relative;
        width: 60px;
        height: 60px;
        text-decoration: none;
        margin-right: 7.5%;
        border: 1px solid white;
        border-radius: 50%;
        text-align: center;
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
        opacity: 0;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
}
.container .controlsContainer .share > a[data-v-2f9d5985]:nth-child(2) {
          -webkit-transition-delay: 0.1s;
                  transition-delay: 0.1s;
}
.container .controlsContainer .share > a .fb[data-v-2f9d5985],
        .container .controlsContainer .share > a .tw[data-v-2f9d5985] {
          position: absolute;
          top: 50%;
          left: 50%;
          max-height: 14px;
          -webkit-transform: translateX(-50%) translateY(-50%);
                  transform: translateX(-50%) translateY(-50%);
}
.container .controlsContainer .share > a .tw[data-v-2f9d5985] {
          max-height: 12px;
}
.container .controlsContainer .share > a.showSocial[data-v-2f9d5985] {
          -webkit-transform: scale(1);
                  transform: scale(1);
          opacity: 1;
}
.container .controlsContainer .share > a[data-v-2f9d5985]:hover {
          border-color: #f6265c;
          -webkit-transform: scale(1.2);
                  transform: scale(1.2);
}
.container:hover .controlsContainer .controls[data-v-2f9d5985] {
    color: rgba(246, 38, 92, 0.3);
}
.container:hover .controlsContainer .controls .pauseBtn[data-v-2f9d5985] {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
}
.fade-enter-active[data-v-2f9d5985],
.fade-leave-active[data-v-2f9d5985] {
  -webkit-transition: all 1.3s;
  transition: all 1.3s;
}
.fade-enter[data-v-2f9d5985],
.fade-leave-to[data-v-2f9d5985] {
  opacity: 0.999;
}

.nextContainer[data-v-1f8089c2] {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 1em;
}
.nextContainer .next .label[data-v-1f8089c2] {
    font-size: 0.8em;
    letter-spacing: 0.3em;
    display: block;
    margin: 24px 0;
}

.logoContainer[data-v-5384aef2] {
  width: 130px;
  float: left;
  position: relative;
  z-index: 99991;
  text-align: left;
  font-size: 1.5em;
  letter-spacing: 0.2em;
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.logoContainer span[data-v-5384aef2] {
    color: #f6265c;
    margin: 0 5px;
}
.logoContainer.entered[data-v-5384aef2] {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
}

.footerContainer[data-v-2713603d] {
  position: absolute;
  z-index: 99991;
  display: block;
  bottom: 0;
  left: 0;
  right: auto;
}
.footerContainer .credits[data-v-2713603d] {
    cursor: none;
    float: left;
    font-size: 0.4em;
    letter-spacing: 0.3em;
    height: 12px;
    vertical-align: top;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
.footerContainer .credits.entered[data-v-2713603d] {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
}
.footerContainer .credits .aqlogo[data-v-2713603d] {
      vertical-align: top;
      display: inline-block;
      margin-top: -37px;
      margin-left: -22px;
}
.footerContainer .credits .label[data-v-2713603d] {
      display: inline;
      vertical-align: middle;
}
.footerContainer .audio[data-v-2713603d] {
    float: right;
}
.footerContainer .skipTutorial[data-v-2713603d] {
    position: fixed;
    bottom: 7.1%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    letter-spacing: 0.2em;
    margin-right: -0.2em;
    text-align: right;
}
.footerContainer .skipTutorial[data-v-2713603d]:after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      background: #f6265c;
      -webkit-filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
      filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
}
.footerContainer .skipTutorial[data-v-2713603d]:hover:after {
      width: 100%;
      -webkit-filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.8));
      filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.8));
}
.footerContainer .konamiCode[data-v-2713603d] {
    position: fixed;
    bottom: 5.2%;
    left: 50%;
    height: 60px;
    width: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    pointer-events: none;
}
.footerContainer .konamiCode.exit .konamiSymbol[data-v-2713603d] {
      -webkit-transition: opacity 0.3s ease-in, -webkit-transform 0.3s ease-in;
      transition: opacity 0.3s ease-in, -webkit-transform 0.3s ease-in;
      transition: opacity 0.3s ease-in, transform 0.3s ease-in;
      transition: opacity 0.3s ease-in, transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
      -webkit-transform: scale(0.7);
              transform: scale(0.7);
      opacity: 0;
}
.footerContainer .konamiCode.exit .konamiSymbol[data-v-2713603d]:nth-of-type(1) {
        -webkit-transition-delay: 1069ms;
                transition-delay: 1069ms;
}
.footerContainer .konamiCode.exit .konamiSymbol[data-v-2713603d]:nth-of-type(2) {
        -webkit-transition-delay: 1008ms;
                transition-delay: 1008ms;
}
.footerContainer .konamiCode.exit .konamiSymbol[data-v-2713603d]:nth-of-type(3) {
        -webkit-transition-delay: 594ms;
                transition-delay: 594ms;
}
.footerContainer .konamiCode.exit .konamiSymbol[data-v-2713603d]:nth-of-type(4) {
        -webkit-transition-delay: 795ms;
                transition-delay: 795ms;
}
.footerContainer .konamiCode.exit .konamiSymbol[data-v-2713603d]:nth-of-type(5) {
        -webkit-transition-delay: 728ms;
                transition-delay: 728ms;
}
.footerContainer .konamiCode.exit .konamiSymbol[data-v-2713603d]:nth-of-type(6) {
        -webkit-transition-delay: 877ms;
                transition-delay: 877ms;
}
.footerContainer .konamiCode.exit .konamiSymbol[data-v-2713603d]:nth-of-type(7) {
        -webkit-transition-delay: 317ms;
                transition-delay: 317ms;
}
.footerContainer .konamiCode.exit .konamiSymbol[data-v-2713603d]:nth-of-type(8) {
        -webkit-transition-delay: 488ms;
                transition-delay: 488ms;
}
.footerContainer .konamiCode.exit .konamiSymbol[data-v-2713603d]:nth-of-type(9) {
        -webkit-transition-delay: 304ms;
                transition-delay: 304ms;
}
.footerContainer .konamiCode.exit .konamiSymbol[data-v-2713603d]:nth-of-type(10) {
        -webkit-transition-delay: 923ms;
                transition-delay: 923ms;
}
.footerContainer .konamiCode .konamiSymbol[data-v-2713603d] {
      position: relative;
      display: none;
      background-color: #f6265c;
      width: 60px;
      height: 60px;
      border-radius: 6px;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-transform: scale(1.3);
              transform: scale(1.3);
}
.footerContainer .konamiCode .konamiSymbol .image[data-v-2713603d] {
        position: absolute;
        opacity: 0;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
                transform: translate(-50%, 0%);
}
.footerContainer .konamiCode .konamiSymbol img[data-v-2713603d] {
        width: 35%;
        height: 35%;
}
.footerContainer .konamiCode .konamiSymbol.active[data-v-2713603d] {
        display: inline-block;
        -webkit-animation: keyEnter-data-v-2713603d 0.35s ease-out forwards;
                animation: keyEnter-data-v-2713603d 0.35s ease-out forwards;
}
.footerContainer .konamiCode .konamiSymbol.active .image[data-v-2713603d] {
          -webkit-animation: keySymEnter-data-v-2713603d 0.35s ease-out forwards;
                  animation: keySymEnter-data-v-2713603d 0.35s ease-out forwards;
}
.footerContainer .konamiCode .konamiSymbol:nth-of-type(3) img[data-v-2713603d] {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
}
.footerContainer .konamiCode .konamiSymbol:nth-of-type(4) img[data-v-2713603d] {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
}
.footerContainer .konamiCode .konamiSymbol:nth-of-type(5) img[data-v-2713603d] {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
}
.footerContainer .konamiCode .konamiSymbol:nth-of-type(6) img[data-v-2713603d] {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
}
.footerContainer .konamiCode .konamiSymbol:nth-of-type(7) img[data-v-2713603d] {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
}
.footerContainer .konamiCode .konamiSymbol:nth-of-type(8) img[data-v-2713603d] {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
}
@-webkit-keyframes keyEnter-data-v-2713603d {
0% {
    display: none;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}
1% {
    display: inline-block;
}
30% {
    background-color: #f6265c;
}
70% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
100% {
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-transform: scale(1);
            transform: scale(1);
}
}
@keyframes keyEnter-data-v-2713603d {
0% {
    display: none;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}
1% {
    display: inline-block;
}
30% {
    background-color: #f6265c;
}
70% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
100% {
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-transform: scale(1);
            transform: scale(1);
}
}
@-webkit-keyframes keySymEnter-data-v-2713603d {
0% {
    opacity: 0;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
}
100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
}
@keyframes keySymEnter-data-v-2713603d {
0% {
    opacity: 0;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
}
100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
}

.audioContainer .audioButton[data-v-4a88863e] {
  cursor: pointer;
  position: relative;
  height: 24px;
  display: block;
  overflow: hidden;
}
.audioContainer .audioButton canvas[data-v-4a88863e] {
    width: 24px;
    height: 20px;
}
@-webkit-keyframes toMute-data-v-4a88863e {
from {
    left: 0px;
}
to {
    left: -170px;
}
}
@keyframes toMute-data-v-4a88863e {
from {
    left: 0px;
}
to {
    left: -170px;
}
}
@-webkit-keyframes toUnMute-data-v-4a88863e {
from {
    left: -170px;
}
to {
    left: -381px;
}
}
@keyframes toUnMute-data-v-4a88863e {
from {
    left: -170px;
}
to {
    left: -381px;
}
}

.loadingContainer[data-v-7421feb7] {
  font-size: 10em;
  font-weight: 800;
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
}
.loadingContainer span[data-v-7421feb7] {
    display: block;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
.loadingContainer span.entered[data-v-7421feb7] {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
}
.loadingContainer span.leaving[data-v-7421feb7] {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      opacity: 0;
}
.loadingContainer .disclaimer[data-v-7421feb7] {
    line-height: 1.4em;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.25);
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
}
.loadingContainer .disclaimer.entered[data-v-7421feb7] {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
}
.loadingContainer .disclaimer.leaving[data-v-7421feb7] {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      opacity: 0;
}

.introLogoContainer[data-v-28997acb] {
  font-size: 2em;
  font-weight: 800;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.introLogoContainer[data-v-28997acb] {
      font-size: 1.8em;
}
}
.introLogoContainer .upper[data-v-28997acb] {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
.introLogoContainer .upper span[data-v-28997acb] {
      margin: 0 5px;
}
.introLogoContainer .upper > svg[data-v-28997acb] {
      min-width: 600px;
}
.introLogoContainer .upper.enteredLogo[data-v-28997acb] {
      opacity: 1;
}
.introLogoContainer .upper.leavingLogo[data-v-28997acb] {
      opacity: 0;
      -webkit-transform: translate(-50%, calc(-100% - 20px));
              transform: translate(-50%, calc(-100% - 20px));
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.introLogoContainer .upper[data-v-28997acb] {
        -webkit-transform: translate(-50%, -120%);
                transform: translate(-50%, -120%);
}
.introLogoContainer .upper.leavingLogo[data-v-28997acb] {
          -webkit-transform: translate(-50%, calc(-120% - 20px));
                  transform: translate(-50%, calc(-120% - 20px));
}
}
@media screen and (max-width: 1536px) and (max-height: 800px) {
.introLogoContainer .upper[data-v-28997acb] {
        -webkit-transform: translate(-50%, -100%);
                transform: translate(-50%, -100%);
}
.introLogoContainer .upper.leavingLogo[data-v-28997acb] {
          -webkit-transform: translate(-50%, calc(-120% - 20px));
                  transform: translate(-50%, calc(-120% - 20px));
}
}
.introLogoContainer .lower[data-v-28997acb] {
    position: absolute;
    top: 50%;
    left: 50%;
    letter-spacing: 1em;
    font-size: 0.5em;
    text-align: center;
    -webkit-transform: translate(-50%, 70%);
            transform: translate(-50%, 70%);
}
.introLogoContainer .lower .label[data-v-28997acb] {
      margin: 30px 0 0;
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.introLogoContainer .lower .eicon img[data-v-28997acb] {
        width: 25%;
}
}

.awayContainer[data-v-5db87033] {
  position: fixed;
  z-index: 9999;
  top: 7.4%;
  right: 4.1%;
  bottom: 7.4%;
  left: 4.1%;
  font-size: 1em;
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.awayContainer[data-v-5db87033] {
      font-size: 0.8em;
}
}
.awayContainer .vs[data-v-5db87033] {
    position: absolute;
    left: 50%;
    top: 46%;
    width: 140px;
    height: 140px;
    line-height: 140px;
    font-size: 3.2em;
    font-weight: 500;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    opacity: 0;
}
.awayContainer .vs.entered[data-v-5db87033] {
      -webkit-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1);
      opacity: 1;
}
.awayContainer .vs.leaving[data-v-5db87033] {
      -webkit-transform: translate(-50%, -50%) scale(1.2);
              transform: translate(-50%, -50%) scale(1.2);
      opacity: 0;
}
.awayContainer .vs .circle-chart[data-v-5db87033] {
      position: absolute;
      left: -3px;
      top: -3px;
}
.awayContainer .vs .circle-chart__circle[data-v-5db87033] {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      -webkit-transform-origin: center;
              transform-origin: center;
      -webkit-transition: stroke-dasharray 1s linear;
      transition: stroke-dasharray 1s linear;
}
.awayContainer .timerContainer[data-v-5db87033] {
    position: absolute;
    bottom: -20px;
    left: 42.5%;
    text-align: center;
    opacity: 0;
    -webkit-transition: bottom 0.4s ease-in-out, opacity 0.3s ease-in-out;
    transition: bottom 0.4s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;
}
.awayContainer .timerContainer.entered[data-v-5db87033] {
      opacity: 1;
      bottom: 0;
}
.awayContainer .timerContainer.leaving[data-v-5db87033] {
      opacity: 0;
      bottom: -20px;
}
.awayContainer .timerContainer .timer[data-v-5db87033] {
      font-size: 12em;
      font-weight: 500;
      -webkit-filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
      filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
}
.awayContainer .pause[data-v-5db87033] {
    padding: 0 0 30px;
    text-align: center;
    position: absolute;
    bottom: -20px;
    left: 75%;
    -webkit-transform: translateX(-50%) translateY(-20px);
            transform: translateX(-50%) translateY(-20px);
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.awayContainer .pause[data-v-5db87033] {
        bottom: 0px;
}
.awayContainer .pause .eicon img[data-v-5db87033] {
          width: 20%;
}
}
@media screen and (max-width: 1366px) and (max-height: 768px) {
.awayContainer .pause[data-v-5db87033] {
        left: 80%;
}
}
@media screen and (max-width: 1280px) and (max-height: 1024px) {
.awayContainer .pause[data-v-5db87033] {
        left: 87.5%;
}
}
@media screen and (max-width: 1280px) and (max-height: 800px) {
.awayContainer .pause[data-v-5db87033] {
        left: 85%;
}
}
@media screen and (max-width: 1280px) and (max-height: 768px) {
.awayContainer .pause[data-v-5db87033] {
        left: 87.5%;
}
}
.awayContainer .pause .label[data-v-5db87033] {
      font-size: 0.8em;
      letter-spacing: 0.3em;
      display: block;
      margin: 24px 0;
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.awayContainer .pause .label[data-v-5db87033] {
          margin: 12px 0;
          font-size: 1em;
}
}
.fade-fast-enter-active[data-v-5db87033] {
  -webkit-animation-name: fade-fast-in-data-v-5db87033;
          animation-name: fade-fast-in-data-v-5db87033;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.fade-fast-leave-active[data-v-5db87033] {
  -webkit-animation: fade-fast-out-data-v-5db87033;
          animation: fade-fast-out-data-v-5db87033;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
@-webkit-keyframes fade-fast-in-data-v-5db87033 {
0% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    opacity: 0;
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
}
@keyframes fade-fast-in-data-v-5db87033 {
0% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    opacity: 0;
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
}
@-webkit-keyframes fade-fast-out-data-v-5db87033 {
0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
}
}
@keyframes fade-fast-out-data-v-5db87033 {
0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
}
}

.sidesContainer[data-v-3a7769da] {
  position: fixed;
  z-index: 9999;
  top: 7.4%;
  right: 4.1%;
  bottom: 7.4%;
  left: 4.1%;
  font-size: 1em;
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.sidesContainer[data-v-3a7769da] {
      font-size: 0.8em;
}
}
.sidesContainer .sideContent[data-v-3a7769da] {
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.sidesContainer .sideContent[data-v-3a7769da] {
        top: 55%;
}
}
.sidesContainer .sideContent .selectlabel[data-v-3a7769da] {
      -webkit-transition: opacity 0.3s cubic-bezier(0.27, 0.73, 0.34, 1), -webkit-transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1);
      transition: opacity 0.3s cubic-bezier(0.27, 0.73, 0.34, 1), -webkit-transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1);
      transition: transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1), opacity 0.3s cubic-bezier(0.27, 0.73, 0.34, 1);
      transition: transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1), opacity 0.3s cubic-bezier(0.27, 0.73, 0.34, 1), -webkit-transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1);
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      opacity: 0;
      letter-spacing: 0.1em;
      -webkit-filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
      filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.sidesContainer .sideContent .selectlabel[data-v-3a7769da] {
          font-size: 1.2em;
}
}
.sidesContainer .sideContent .selectlabel.entered[data-v-3a7769da] {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        opacity: 1;
}
.sidesContainer .sideContent .selectlabel.leaving[data-v-3a7769da] {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
        opacity: 0;
}
.sidesContainer .sideContent .homeTeam[data-v-3a7769da] {
      -webkit-transition: opacity 0.3s cubic-bezier(0.27, 0.73, 0.34, 1), -webkit-transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1);
      transition: opacity 0.3s cubic-bezier(0.27, 0.73, 0.34, 1), -webkit-transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1);
      transition: transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1), opacity 0.3s cubic-bezier(0.27, 0.73, 0.34, 1);
      transition: transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1), opacity 0.3s cubic-bezier(0.27, 0.73, 0.34, 1), -webkit-transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1);
      -webkit-transition-delay: 0.1s;
              transition-delay: 0.1s;
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      opacity: 0;
      font-size: 10em;
      font-weight: 500;
      margin-bottom: 2.5%;
      -webkit-filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
      filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
}
.sidesContainer .sideContent .homeTeam.entered[data-v-3a7769da] {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        opacity: 1;
}
.sidesContainer .sideContent .homeTeam.leaving[data-v-3a7769da] {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
        opacity: 0;
}
.sidesContainer .sideContent .lower[data-v-3a7769da] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
}
.sidesContainer .sideContent .lower .left[data-v-3a7769da],
      .sidesContainer .sideContent .lower .right[data-v-3a7769da] {
        position: relative;
        width: 140px;
        height: 140px;
        -webkit-transition: opacity 0.4s cubic-bezier(0.27, 0.73, 0.34, 1), -webkit-transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1);
        transition: opacity 0.4s cubic-bezier(0.27, 0.73, 0.34, 1), -webkit-transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1);
        transition: transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1), opacity 0.4s cubic-bezier(0.27, 0.73, 0.34, 1);
        transition: transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1), opacity 0.4s cubic-bezier(0.27, 0.73, 0.34, 1), -webkit-transform 0.4s cubic-bezier(0.27, 0.73, 0.34, 1);
        -webkit-transform: scale(0);
                transform: scale(0);
}
.sidesContainer .sideContent .lower .left .circle-chart[data-v-3a7769da],
        .sidesContainer .sideContent .lower .right .circle-chart[data-v-3a7769da] {
          position: absolute;
          left: -3px;
          top: -3px;
          -webkit-transition: -webkit-filter 0.4s ease-in-out;
          transition: -webkit-filter 0.4s ease-in-out;
          transition: filter 0.4s ease-in-out;
          transition: filter 0.4s ease-in-out, -webkit-filter 0.4s ease-in-out;
          -webkit-filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
          filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
}
.sidesContainer .sideContent .lower .left.entered[data-v-3a7769da],
        .sidesContainer .sideContent .lower .right.entered[data-v-3a7769da] {
          -webkit-transform: scale(1);
                  transform: scale(1);
}
.sidesContainer .sideContent .lower .left.leaving[data-v-3a7769da],
        .sidesContainer .sideContent .lower .right.leaving[data-v-3a7769da] {
          -webkit-transform: scale(1.2);
                  transform: scale(1.2);
          opacity: 0;
}
.sidesContainer .sideContent .lower .left .circle-chart__circle[data-v-3a7769da],
        .sidesContainer .sideContent .lower .right .circle-chart__circle[data-v-3a7769da] {
          stroke: #fff;
          -webkit-transform: rotate(-90deg);
                  transform: rotate(-90deg);
          -webkit-transform-origin: center;
                  transform-origin: center;
          -webkit-transition: stroke-dasharray 0.4s linear;
          transition: stroke-dasharray 0.4s linear;
}
.sidesContainer .sideContent .lower .left span[data-v-3a7769da],
        .sidesContainer .sideContent .lower .right span[data-v-3a7769da] {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
                  transform: translateX(-50%) translateY(-50%);
          font-size: 2.8em;
          -webkit-transition: -webkit-filter 0.4s ease-in-out;
          transition: -webkit-filter 0.4s ease-in-out;
          transition: filter 0.4s ease-in-out;
          transition: filter 0.4s ease-in-out, -webkit-filter 0.4s ease-in-out;
          -webkit-filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
          filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
}
.sidesContainer .sideContent .lower .left:hover:not(.active) .circle-chart[data-v-3a7769da],
        .sidesContainer .sideContent .lower .left:hover:not(.active) span[data-v-3a7769da],
        .sidesContainer .sideContent .lower .right:hover:not(.active) .circle-chart[data-v-3a7769da],
        .sidesContainer .sideContent .lower .right:hover:not(.active) span[data-v-3a7769da] {
          -webkit-filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.8));
          filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.8));
}
.sidesContainer .sideContent .lower .left.active .circle-chart__circle[data-v-3a7769da],
        .sidesContainer .sideContent .lower .right.active .circle-chart__circle[data-v-3a7769da] {
          stroke: #f6265c;
}
.sidesContainer .sideContent .lower .left.active .circle-chart[data-v-3a7769da],
        .sidesContainer .sideContent .lower .right.active .circle-chart[data-v-3a7769da] {
          -webkit-filter: drop-shadow(0 0 6px rgba(246, 38, 92, 0.8));
          filter: drop-shadow(0 0 6px rgba(246, 38, 92, 0.8));
}
.sidesContainer .sideContent .lower .left.active span[data-v-3a7769da],
        .sidesContainer .sideContent .lower .right.active span[data-v-3a7769da] {
          -webkit-filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.8));
          filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.8));
}
.sidesContainer .sideContent .lower .left[data-v-3a7769da] {
        -webkit-transition-delay: 0.15s;
                transition-delay: 0.15s;
}
.sidesContainer .sideContent .lower .right[data-v-3a7769da] {
        -webkit-transition-delay: 0.25s;
                transition-delay: 0.25s;
}
.sidesContainer .sideContent .lower .center[data-v-3a7769da] {
        vertical-align: top;
        margin: -5px 25px 0;
}
.sidesContainer .sideContent .lower .center > div[data-v-3a7769da] {
          display: inline-block;
          padding: 0;
          margin: 0;
          font-size: 0;
}
.sidesContainer .sideContent .lower .center .lineSx[data-v-3a7769da],
        .sidesContainer .sideContent .lower .center .lineDx[data-v-3a7769da] {
          -webkit-transition: width 0.4s cubic-bezier(0.27, 0.73, 0.34, 1), opacity 0.4s cubic-bezier(0.27, 0.73, 0.34, 1);
          transition: width 0.4s cubic-bezier(0.27, 0.73, 0.34, 1), opacity 0.4s cubic-bezier(0.27, 0.73, 0.34, 1);
          -webkit-transition-delay: 0.1s;
                  transition-delay: 0.1s;
          width: 0px;
          height: 2px;
          position: relative;
          background-color: rgba(255, 255, 255, 0.3);
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.sidesContainer .sideContent .lower .center .lineSx[data-v-3a7769da],
            .sidesContainer .sideContent .lower .center .lineDx[data-v-3a7769da] {
              width: 0px;
}
}
.sidesContainer .sideContent .lower .center .lineSx.entered[data-v-3a7769da],
          .sidesContainer .sideContent .lower .center .lineDx.entered[data-v-3a7769da] {
            width: 200px;
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.sidesContainer .sideContent .lower .center .lineSx.entered[data-v-3a7769da],
              .sidesContainer .sideContent .lower .center .lineDx.entered[data-v-3a7769da] {
                width: 180px;
}
}
.sidesContainer .sideContent .lower .center .lineSx.leaving[data-v-3a7769da],
          .sidesContainer .sideContent .lower .center .lineDx.leaving[data-v-3a7769da] {
            opacity: 0;
}
.sidesContainer .sideContent .lower .center .lineSx[data-v-3a7769da]:after,
          .sidesContainer .sideContent .lower .center .lineDx[data-v-3a7769da]:after {
            position: absolute;
            top: 0;
            content: '';
            width: 0;
            height: 2px;
            background-color: #ffffff;
            will-change: width;
            -webkit-transition-duration: 0.2s;
                    transition-duration: 0.2s;
            -webkit-transition-property: width;
            transition-property: width;
            -webkit-transition-delay: 0s;
                    transition-delay: 0s;
            -webkit-transition-timing-function: ease-in;
                    transition-timing-function: ease-in;
}
.sidesContainer .sideContent .lower .center .lineSx.active[data-v-3a7769da]:after,
          .sidesContainer .sideContent .lower .center .lineDx.active[data-v-3a7769da]:after {
            -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
            -webkit-transition-delay: 0.2s;
                    transition-delay: 0.2s;
            width: 100%;
}
.sidesContainer .sideContent .lower .center .lineSx[data-v-3a7769da]:after {
          right: 0;
}
.sidesContainer .sideContent .lower .center .lineDx[data-v-3a7769da]:after {
          left: 0;
}
.sidesContainer .sideContent .next[data-v-3a7769da] {
      padding: 50px 0;
      font-size: 0.8em;
      letter-spacing: 0.3em;
}
.sidesContainer .sideContent .next .label[data-v-3a7769da] {
        display: block;
        margin: 16px 0;
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.sidesContainer .sideContent .next[data-v-3a7769da] {
          font-size: 1em;
}
.sidesContainer .sideContent .next .eicon img[data-v-3a7769da] {
            width: 75%;
}
}
.fade-enter-active[data-v-3a7769da] {
  will-change: opacity;
  -webkit-transition-duration: 0s;
          transition-duration: 0s;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}
.fade-leave-active[data-v-3a7769da] {
  will-change: opacity;
  -webkit-transition-duration: 0s;
          transition-duration: 0s;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}
.fade-enter[data-v-3a7769da],
.fade-leave-to[data-v-3a7769da] {
  opacity: 0;
}

.mousePointerContainer[data-v-11a8439f] {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99992;
  pointer-events: none;
}
.mousePointerContainer.inGame[data-v-11a8439f] {
    opacity: 0.2;
}
.mousePointerContainer.inGame .cursor[data-v-11a8439f] {
      background-color: #fff;
      width: 8px;
      height: 8px;
}
.mousePointerContainer.overHeader[data-v-11a8439f] {
    opacity: 1;
}
.mousePointerContainer.overHeader .cursor[data-v-11a8439f] {
      background-color: #f6265c;
      width: 16px;
      height: 16px;
}
.mousePointerContainer .cursor[data-v-11a8439f] {
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #f6265c;
    -webkit-transition-property: background-color, width, height;
    transition-property: background-color, width, height;
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    -webkit-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out;
    border-radius: 18px;
}

.tutorialContainer[data-v-407a95e2] {
  font-size: 1em;
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.tutorialContainer[data-v-407a95e2] {
      font-size: 0.7em;
}
}
.tutorialContainer .timerContainer[data-v-407a95e2] {
    text-align: center;
    color: #ffffff;
    margin-bottom: 2%;
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
.tutorialContainer .timerContainer.entered[data-v-407a95e2] {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-transition-delay: 0.1s;
              transition-delay: 0.1s;
}
.tutorialContainer .timerContainer.leaving[data-v-407a95e2] {
      opacity: 0;
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      -webkit-transition-delay: 0s;
              transition-delay: 0s;
}
.tutorialContainer .timerContainer .timer[data-v-407a95e2] {
      font-size: 12em;
      font-weight: 500;
      -webkit-filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
      filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
}
.tutorialContainer .upper div[data-v-407a95e2] {
    vertical-align: bottom;
    display: inline-block;
    width: auto;
    height: 140px;
    line-height: 140px;
    -webkit-filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.4));
}
.tutorialContainer .upper .vs[data-v-407a95e2] {
    position: relative;
    width: 140px;
    border-radius: 100%;
    font-size: 2.8em;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    opacity: 0;
}
.tutorialContainer .upper .vs .circle-chart[data-v-407a95e2] {
      position: absolute;
      left: -3px;
      top: -3px;
}
.tutorialContainer .upper .vs .circle-chart__circle[data-v-407a95e2] {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      -webkit-transform-origin: center;
              transform-origin: center;
      -webkit-transition: stroke-dasharray 1s linear;
      transition: stroke-dasharray 1s linear;
}
.tutorialContainer .upper .vs.entered[data-v-407a95e2] {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
      -webkit-transition-delay: 0;
              transition-delay: 0;
}
.tutorialContainer .upper .vs.leaving[data-v-407a95e2] {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 0;
      -webkit-transition-delay: 0.1s;
              transition-delay: 0.1s;
}
.tutorialContainer .upper .team[data-v-407a95e2] {
    font-size: 6em;
    letter-spacing: 0.1em;
    font-weight: normal;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
.tutorialContainer .upper .team[data-v-407a95e2]:first-of-type {
      margin-right: 12.5%;
}
.tutorialContainer .upper .team[data-v-407a95e2]:last-of-type {
      margin-left: 12.5%;
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.tutorialContainer .upper .team[data-v-407a95e2]:first-of-type {
        margin-right: 8.5%;
}
.tutorialContainer .upper .team[data-v-407a95e2]:last-of-type {
        margin-left: 8.5%;
}
}
.tutorialContainer .upper .team.entered[data-v-407a95e2] {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
}
.tutorialContainer .upper .team.leaving[data-v-407a95e2] {
      -webkit-transform: translateX(-20px);
              transform: translateX(-20px);
      opacity: 0;
}
.tutorialContainer .upper .team.leaving[data-v-407a95e2]:last-of-type {
        -webkit-transform: translateX(20px);
                transform: translateX(20px);
}
.tutorialContainer .lower[data-v-407a95e2] {
    margin-top: 2.5%;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
.tutorialContainer .lower.entered[data-v-407a95e2] {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
      -webkit-transition-delay: 0.1s;
              transition-delay: 0.1s;
}
.tutorialContainer .lower.leaving[data-v-407a95e2] {
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
      opacity: 0;
      -webkit-transition-delay: 0s;
              transition-delay: 0s;
}
@media screen and (max-height: 668px) {
.tutorialContainer .lower[data-v-407a95e2] {
        margin-top: 1%;
}
}
.tutorialContainer .lower div[data-v-407a95e2] {
      margin: 0 40px;
      display: inline-block;
      width: 280px;
      height: 280px;
      vertical-align: middle;
}
@media screen and (max-width: 1536px) and (max-height: 1024px) {
.tutorialContainer .lower div[data-v-407a95e2] {
          margin: 0 30px;
          width: 230px;
          height: 230px;
}
}
@media screen and (max-height: 668px) {
.tutorialContainer .lower div[data-v-407a95e2] {
          width: 200px;
          height: 200px;
}
}
.fade-fast-enter-active[data-v-407a95e2] {
  -webkit-animation-name: fade-fast-in-data-v-407a95e2;
          animation-name: fade-fast-in-data-v-407a95e2;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.fade-fast-leave-active[data-v-407a95e2] {
  -webkit-animation: fade-fast-out-data-v-407a95e2;
          animation: fade-fast-out-data-v-407a95e2;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
@-webkit-keyframes fade-fast-in-data-v-407a95e2 {
0% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    opacity: 0;
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
}
@keyframes fade-fast-in-data-v-407a95e2 {
0% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    opacity: 0;
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
}
@-webkit-keyframes fade-fast-out-data-v-407a95e2 {
0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
}
}
@keyframes fade-fast-out-data-v-407a95e2 {
0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
}
}

.goalContainer[data-v-93c0b306] {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
}
.goalContainer .goal[data-v-93c0b306] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    letter-spacing: 0.2em;
    font-size: 12em;
    text-shadow: 0 0 20px black;
}

.countdownContainer[data-v-a38e511e] {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
}
.countdownContainer .countdown > div[data-v-a38e511e] {
    position: absolute;
    width: 100%;
    height: 100%;
}

.cookieContainer[data-v-6bd8b0ec] {
  font-size: 0.6em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-align: center;
  position: fixed;
  bottom: 6.9%;
  text-rendering: optimizeLegibility;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  z-index: 9999;
}
.cookieContainer .bar[data-v-6bd8b0ec] {
    display: inline-block;
    padding: 10px 22px;
    background-color: rgba(7, 10, 41, 0.8);
    color: rgba(255, 255, 255, 0.6);
    width: auto;
}
.cookieContainer .bar a[data-v-6bd8b0ec] {
      text-decoration: none;
      color: #ffffff;
      cursor: none;
      display: inline-block;
      position: relative;
}
.cookieContainer .bar a[data-v-6bd8b0ec]:hover {
        cursor: none;
}
.cookieContainer .bar a[data-v-6bd8b0ec]:first-of-type {
        margin-left: 30px;
        margin-right: 20px;
}
.cookieContainer .bar a[data-v-6bd8b0ec]:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0;
        height: 2px;
        background: #f6265c;
        -webkit-filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
        filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.4));
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
}
.cookieContainer .bar a[data-v-6bd8b0ec]:hover:after {
        width: 100%;
        -webkit-filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.8));
        filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.8));
}
.fade-enter-active[data-v-6bd8b0ec],
.fade-leave-active[data-v-6bd8b0ec] {
  will-change: opacity;
  -webkit-transition-duration: 0.6s;
          transition-duration: 0.6s;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}
.fade-enter[data-v-6bd8b0ec],
.fade-leave-to[data-v-6bd8b0ec] {
  opacity: 0;
}

.outContainer[data-v-d4a3a062] {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99996;
}
.fade-enter-active[data-v-d4a3a062],
.fade-leave-active[data-v-d4a3a062] {
  will-change: opacity;
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}
.fade-enter[data-v-d4a3a062],
.fade-leave-to[data-v-d4a3a062] {
  opacity: 0;
}

@font-face {
  font-family: 'AHAMONO';
  src: url("/static/fonts/AHAMONO-Regular.otf");
  /* IE9 Compat Modes */
  src: url("/static/fonts/AHAMONO-Regular.eot?#iefix") format("embedded-opentype"), url("/static/fonts/AHAMONO-Regular.woff2") format("woff2"), url("/static/fonts/AHAMONO-Regular.woff") format("woff");
  /* Pretty Modern Browsers */
}
.container[data-v-87ece8ec] {
  position: fixed;
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #000;
  font-family: 'AHAMONO';
  background-position: center center;
  background-size: cover;
  cursor: default;
}
.container strong[data-v-87ece8ec],
  .container h1[data-v-87ece8ec] {
    color: white;
}
.container .privacyContainer[data-v-87ece8ec] {
    position: fixed;
    z-index: 9999;
    top: 15%;
    left: 20%;
    right: 20%;
    bottom: 15%;
    line-height: 1.2em;
    text-align: left;
    overflow: hidden;
    overflow-y: scroll;
}

.overlayContainer[data-v-38c2603a] {
  position: fixed;
  z-index: 9999;
  top: 7.4%;
  right: 4.1%;
  bottom: 7.4%;
  left: 4.1%;
}

@font-face {
  font-family: 'AHAMONO';
  src: url("/static/fonts/AHAMONO-Regular.otf");
  /* IE9 Compat Modes */
  src: url("/static/fonts/AHAMONO-Regular.eot?#iefix") format("embedded-opentype"), url("/static/fonts/AHAMONO-Regular.woff2") format("woff2"), url("/static/fonts/AHAMONO-Regular.woff") format("woff");
  /* Pretty Modern Browsers */
}
.wrapper[data-v-04bb1a95] {
  width: 100%;
  height: 100%;
  background-color: #0b0f21;
  font-family: 'AHAMONO';
  background-image: url("/static/imgs/bg_stats_mini.jpg");
  background-position: center center;
  background-size: cover;
}
.wrapper .alertRotate[data-v-04bb1a95] {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
@media screen and (orientation: portrait) and (min-width: 767px) {
.wrapper .alertRotate[data-v-04bb1a95] {
        display: block;
}
}
@media screen and (orientation: landscape) and (max-width: 767px) {
.wrapper .alertRotate[data-v-04bb1a95] {
        display: block;
}
}
.wrapper .alertRotate .message[data-v-04bb1a95] {
      position: absolute;
      top: 60%;
      left: 50%;
      width: 100%;
      color: #fff;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      font-size: 1.5em;
}
.wrapper .alertRotate .mobile[data-v-04bb1a95] {
      position: absolute;
      top: 40%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
}
@media screen and (orientation: landscape) {
.wrapper .alertRotate .mobile[data-v-04bb1a95] {
          -webkit-transform: translate(-50%, -50%) rotate(90deg);
                  transform: translate(-50%, -50%) rotate(90deg);
}
}
.wrapper .alertRotate .mobile img[data-v-04bb1a95] {
        -webkit-transform-origin: center center;
                transform-origin: center center;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
}
.wrapper .containerPortrait[data-v-04bb1a95] {
    position: fixed;
    left: 20px;
    right: 20px;
    top: 20px;
    bottom: 20px;
    color: white;
    display: none;
    font-size: 0.9em;
}
@media screen and (orientation: portrait) and (max-width: 767px) {
.wrapper .containerPortrait[data-v-04bb1a95] {
        display: block;
}
}
.wrapper .containerLandscape[data-v-04bb1a95] {
    display: none;
    position: fixed;
    left: 50px;
    right: 50px;
    top: 60px;
    bottom: 60px;
    color: white;
    cursor: none;
    font-size: 0.75em;
}
@media screen and (orientation: portrait) {
.wrapper .containerLandscape[data-v-04bb1a95] {
        display: none;
}
}
@media screen and (min-width: 1024px) {
.wrapper .containerLandscape[data-v-04bb1a95] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
}
}
@media screen and (min-width: 1280px) {
.wrapper .containerLandscape[data-v-04bb1a95] {
        font-size: 1em;
}
}
@media screen and (min-width: 1900px) {
.wrapper .containerLandscape[data-v-04bb1a95] {
        font-size: 1.4em;
}
}
.wrapper .containerLandscape .column[data-v-04bb1a95] {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      width: 35%;
}
.wrapper .containerLandscape .column[data-v-04bb1a95]:nth-of-type(2) {
        width: 30%;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.carousel[data-v-4e44902d] {
  position: relative;
  width: 100%;
  height: 100%;
}
.carousel .content .slide[data-v-4e44902d] {
    position: absolute;
    left: 0;
    height: 92%;
    width: 100%;
    z-index: 2;
}
.carousel .content .slide.active[data-v-4e44902d] {
      z-index: 10;
}
.carousel .footer[data-v-4e44902d] {
    text-align: center;
    position: absolute;
    display: block;
    bottom: 0;
    width: 100%;
    height: 30px;
}
.carousel .footer .point[data-v-4e44902d] {
      position: relative;
      width: 10px;
      height: 10px;
      border: 1px solid #f41d48;
      border-radius: 10px;
      display: inline-block;
      margin: 0 10px 0;
      vertical-align: middle;
      background-color: rgba(244, 29, 72, 0);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-transition-property: background-color, width, height;
      transition-property: background-color, width, height;
      -webkit-transition-duration: 0.25s;
              transition-duration: 0.25s;
      -webkit-transition-timing-function: ease-in-out;
              transition-timing-function: ease-in-out;
}
.carousel .footer .point.active[data-v-4e44902d] {
        background-color: #f41d48;
}
.carousel.first .footer[data-v-4e44902d] {
    text-align: left;
}
.carousel.last .footer[data-v-4e44902d] {
    text-align: right;
}
.carousel.portrait[data-v-4e44902d] {
    text-align: left;
}
.carousel.portrait .footer[data-v-4e44902d] {
      text-align: center;
      position: absolute;
      bottom: 0;
      width: 100%;
}
.carousel.portrait .footer .point[data-v-4e44902d] {
        width: 4px;
        height: 4px;
        border: 1px solid #f41d48;
        border-radius: 10px;
        display: inline-block;
        vertical-align: middle;
        background-color: rgba(244, 29, 72, 0);
        -webkit-transition-property: background-color, width, height;
        transition-property: background-color, width, height;
        -webkit-transition-duration: 0.25s;
                transition-duration: 0.25s;
        -webkit-transition-timing-function: ease-in-out;
                transition-timing-function: ease-in-out;
}
.carousel.portrait .footer .point.active[data-v-4e44902d] {
          background-color: #f41d48;
}

.container[data-v-5112f31d] {
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.container .row[data-v-5112f31d] {
    height: 27%;
    width: 100%;
    padding-top: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    -webkit-transition: border-bottom 0.3s ease-out;
    transition: border-bottom 0.3s ease-out;
}
.container .row.show[data-v-5112f31d] {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.container .row.exit[data-v-5112f31d] {
      border-bottom: 1px solid rgba(255, 255, 255, 0);
}
.container .row[data-v-5112f31d]:nth-of-type(1) {
      height: 46%;
      padding-top: 0px;
}
.container .row[data-v-5112f31d]:nth-of-type(3) {
      border-bottom: 0px solid;
}
.container .separator[data-v-5112f31d] {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.1);
}
.container .title[data-v-5112f31d] {
    font-size: 1.6em;
    color: #f41d48;
}
.container .title.big[data-v-5112f31d] {
      font-size: 4.5em;
}
.container .number[data-v-5112f31d] {
    font-size: 6em;
    color: #fff;
}
.container .number.big[data-v-5112f31d] {
      font-size: 10em;
}
.container .slider[data-v-5112f31d] {
    margin-left: 50px;
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    -webkit-transition-delay: 0.05s;
            transition-delay: 0.05s;
    opacity: 0;
}
.container .slider.show[data-v-5112f31d] {
      -webkit-transition: opacity 0.3s ease-out, margin-left 0.6s cubic-bezier(0.75, 0, 0, 1.75);
      transition: opacity 0.3s ease-out, margin-left 0.6s cubic-bezier(0.75, 0, 0, 1.75);
      -webkit-transition-delay: 0.5s;
              transition-delay: 0.5s;
      margin-left: 0px;
      opacity: 1;
}
.container .slider.exit[data-v-5112f31d] {
      -webkit-transition: opacity 0.3s ease-in, margin-left 0.3s ease-in;
      transition: opacity 0.3s ease-in, margin-left 0.3s ease-in;
      -webkit-transition-delay: 0.05s;
              transition-delay: 0.05s;
      margin-left: -30px;
      opacity: 0;
}
.container .row:nth-of-type(1) .show[data-v-5112f31d]:nth-of-type(1) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms;
}
.container .row:nth-of-type(1) .show[data-v-5112f31d]:nth-of-type(2) {
    -webkit-transition-delay: 200ms;
            transition-delay: 200ms;
}
.container .row:nth-of-type(2) .show[data-v-5112f31d]:nth-of-type(1) {
    -webkit-transition-delay: 250ms;
            transition-delay: 250ms;
}
.container .row:nth-of-type(2) .show[data-v-5112f31d]:nth-of-type(2) {
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms;
}
.container .row:nth-of-type(3) .show[data-v-5112f31d]:nth-of-type(1) {
    -webkit-transition-delay: 350ms;
            transition-delay: 350ms;
}
.container .row:nth-of-type(3) .show[data-v-5112f31d]:nth-of-type(2) {
    -webkit-transition-delay: 400ms;
            transition-delay: 400ms;
}
.container.portrait .row[data-v-5112f31d] {
    padding-top: 10px;
}
.container.portrait .row[data-v-5112f31d]:nth-of-type(1) {
      padding-top: 0px;
}
.container.portrait .title[data-v-5112f31d] {
    font-size: 1.6em;
}
.container.portrait .title.big[data-v-5112f31d] {
      font-size: 4.5em;
}
.container.portrait .number[data-v-5112f31d] {
    font-size: 5em;
    color: #fff;
}
.container.portrait .number.big[data-v-5112f31d] {
      font-size: 9em;
}
.container.portrait .map[data-v-5112f31d] {
    left: 0px !important;
}

.container[data-v-0d4c5d49] {
  text-align: center;
}
.container .imageContainer[data-v-0d4c5d49] {
    position: relative;
    width: 200px;
    height: 200px;
    position: relative;
    margin: 0 auto 20px;
}
@media screen and (min-width: 1900px) {
.container .imageContainer[data-v-0d4c5d49] {
        width: 250px;
        height: 250px;
}
}
@media screen and (min-width: 1900px) {
.container .imageContainer[data-v-0d4c5d49] {
        width: 300px;
        height: 300px;
}
}
.container .imageContainer .image[data-v-0d4c5d49] {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 0px;
      height: 0px;
      border: 2px solid #fff;
      border-radius: 200px;
      margin: 0 auto;
      -webkit-transition: width 0.6s cubic-bezier(0.09, 0.66, 0.37, 1), height 0.6s cubic-bezier(0.09, 0.66, 0.37, 1), opacity 0.6s ease-in;
      transition: width 0.6s cubic-bezier(0.09, 0.66, 0.37, 1), height 0.6s cubic-bezier(0.09, 0.66, 0.37, 1), opacity 0.6s ease-in;
      -webkit-transition-delay: 0.25s;
              transition-delay: 0.25s;
      opacity: 0;
      overflow: hidden;
}
.container .imageContainer .image > img[data-v-0d4c5d49] {
        width: 95%;
        margin-top: 180px;
        -webkit-transition: margin-top 0.6s cubic-bezier(0.09, 0.66, 0.37, 1), opacity 0.6s ease-out;
        transition: margin-top 0.6s cubic-bezier(0.09, 0.66, 0.37, 1), opacity 0.6s ease-out;
        -webkit-transition-delay: 0.5s;
                transition-delay: 0.5s;
        opacity: 0;
}
.container .imageContainer .image.show[data-v-0d4c5d49] {
        -webkit-transition: width 0.6s cubic-bezier(0.09, 0.66, 0.37, 1), height 0.6s cubic-bezier(0.09, 0.66, 0.37, 1), opacity 0.6s ease-out;
        transition: width 0.6s cubic-bezier(0.09, 0.66, 0.37, 1), height 0.6s cubic-bezier(0.09, 0.66, 0.37, 1), opacity 0.6s ease-out;
        -webkit-transition-delay: 0.5s;
                transition-delay: 0.5s;
        width: 200px;
        height: 200px;
        opacity: 1;
}
@media screen and (min-width: 1900px) {
.container .imageContainer .image.show[data-v-0d4c5d49] {
            width: 250px;
            height: 250px;
}
}
@media screen and (min-width: 1900px) {
.container .imageContainer .image.show[data-v-0d4c5d49] {
            width: 300px;
            height: 300px;
}
}
.container .imageContainer .image.show > img[data-v-0d4c5d49] {
          width: 95%;
          margin-top: 20px;
          opacity: 1;
}
.container .title[data-v-0d4c5d49] {
    font-size: 1.6em;
    color: #f41d48;
    line-height: 1em;
}
.container .title.big[data-v-0d4c5d49] {
      font-size: 4.5em;
}
.container .ndata[data-v-0d4c5d49] {
    margin: 20px 0;
    font-size: 3.6em;
    line-height: 1em;
}
.container .slug[data-v-0d4c5d49] {
    font-size: 10em;
    color: #fff;
}
.container .scaler[data-v-0d4c5d49] {
    -webkit-transition: opacity 0.6s ease-in, -webkit-transform 0.6s ease-out;
    transition: opacity 0.6s ease-in, -webkit-transform 0.6s ease-out;
    transition: transform 0.6s ease-out, opacity 0.6s ease-in;
    transition: transform 0.6s ease-out, opacity 0.6s ease-in, -webkit-transform 0.6s ease-out;
    -webkit-transition-delay: 0;
            transition-delay: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
}
.container .scaler.show[data-v-0d4c5d49] {
      -webkit-transition: opacity 0.6s ease-out, -webkit-transform 0.6s cubic-bezier(0.75, 0, 0, 1.75);
      transition: opacity 0.6s ease-out, -webkit-transform 0.6s cubic-bezier(0.75, 0, 0, 1.75);
      transition: transform 0.6s cubic-bezier(0.75, 0, 0, 1.75), opacity 0.6s ease-out;
      transition: transform 0.6s cubic-bezier(0.75, 0, 0, 1.75), opacity 0.6s ease-out, -webkit-transform 0.6s cubic-bezier(0.75, 0, 0, 1.75);
      -webkit-transition-delay: 0.5s;
              transition-delay: 0.5s;
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
}
.container .scaler.exit[data-v-0d4c5d49] {
      -webkit-transition: opacity 0.6s ease-in, -webkit-transform 0.6s cubic-bezier(0.6, 0.01, 0.93, 0.61);
      transition: opacity 0.6s ease-in, -webkit-transform 0.6s cubic-bezier(0.6, 0.01, 0.93, 0.61);
      transition: transform 0.6s cubic-bezier(0.6, 0.01, 0.93, 0.61), opacity 0.6s ease-in;
      transition: transform 0.6s cubic-bezier(0.6, 0.01, 0.93, 0.61), opacity 0.6s ease-in, -webkit-transform 0.6s cubic-bezier(0.6, 0.01, 0.93, 0.61);
      -webkit-transition-delay: 0;
              transition-delay: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
}
.container .scaler:nth-of-type(1).show[data-v-0d4c5d49] {
    -webkit-transition-delay: 100ms;
            transition-delay: 100ms;
}
.container .scaler:nth-of-type(1).exit[data-v-0d4c5d49] {
    -webkit-transition-delay: 50ms;
            transition-delay: 50ms;
}
.container .scaler:nth-of-type(2).show[data-v-0d4c5d49] {
    -webkit-transition-delay: 200ms;
            transition-delay: 200ms;
}
.container .scaler:nth-of-type(2).exit[data-v-0d4c5d49] {
    -webkit-transition-delay: 100ms;
            transition-delay: 100ms;
}
.container .scaler:nth-of-type(3).show[data-v-0d4c5d49] {
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms;
}
.container .scaler:nth-of-type(3).exit[data-v-0d4c5d49] {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms;
}
.container .scaler:nth-of-type(4).show[data-v-0d4c5d49] {
    -webkit-transition-delay: 400ms;
            transition-delay: 400ms;
}
.container .scaler:nth-of-type(4).exit[data-v-0d4c5d49] {
    -webkit-transition-delay: 200ms;
            transition-delay: 200ms;
}
.container .scaler:nth-of-type(5).show[data-v-0d4c5d49] {
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms;
}
.container .scaler:nth-of-type(5).exit[data-v-0d4c5d49] {
    -webkit-transition-delay: 250ms;
            transition-delay: 250ms;
}
.container .scaler:nth-of-type(6).show[data-v-0d4c5d49] {
    -webkit-transition-delay: 600ms;
            transition-delay: 600ms;
}
.container .scaler:nth-of-type(6).exit[data-v-0d4c5d49] {
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms;
}
.container .scaler:nth-of-type(7).show[data-v-0d4c5d49] {
    -webkit-transition-delay: 700ms;
            transition-delay: 700ms;
}
.container .scaler:nth-of-type(7).exit[data-v-0d4c5d49] {
    -webkit-transition-delay: 350ms;
            transition-delay: 350ms;
}
.container .scaler:nth-of-type(8).show[data-v-0d4c5d49] {
    -webkit-transition-delay: 800ms;
            transition-delay: 800ms;
}
.container .scaler:nth-of-type(8).exit[data-v-0d4c5d49] {
    -webkit-transition-delay: 400ms;
            transition-delay: 400ms;
}
.container .scaler:nth-of-type(9).show[data-v-0d4c5d49] {
    -webkit-transition-delay: 900ms;
            transition-delay: 900ms;
}
.container .scaler:nth-of-type(9).exit[data-v-0d4c5d49] {
    -webkit-transition-delay: 450ms;
            transition-delay: 450ms;
}
.container .scaler:nth-of-type(10).show[data-v-0d4c5d49] {
    -webkit-transition-delay: 1000ms;
            transition-delay: 1000ms;
}
.container .scaler:nth-of-type(10).exit[data-v-0d4c5d49] {
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms;
}
.container.portrait .ndata[data-v-0d4c5d49] {
    margin: 10px 0;
    font-size: 3.4em;
    line-height: 1em;
}
.container.portrait .slug[data-v-0d4c5d49] {
    font-size: 8em;
    line-height: 1em;
    color: #fff;
}

.container[data-v-1f0e9ad8] {
  text-align: right;
}
.container .title[data-v-1f0e9ad8] {
    font-size: 1.6em;
    color: #f41d48;
    line-height: 1em;
}
.container .title.big[data-v-1f0e9ad8] {
      font-size: 4.5em;
}
.container .ranks[data-v-1f0e9ad8] {
    display: inline-block;
    margin-top: 40px;
    width: 90%;
}
.container .ranks .item[data-v-1f0e9ad8] {
      position: absolute;
      left: 60px;
      right: 0;
      display: block;
      opacity: 0;
      -webkit-transition: opacity 0.3s ease-out;
      transition: opacity 0.3s ease-out;
      -webkit-transition-delay: 0s;
              transition-delay: 0s;
}
.container .ranks .item.active[data-v-1f0e9ad8] {
        -webkit-transition: opacity 0.3s ease-out;
        transition: opacity 0.3s ease-out;
        -webkit-transition-delay: 0.3s;
                transition-delay: 0.3s;
        opacity: 1;
}
.container .ranks .item .slug[data-v-1f0e9ad8] {
        font-size: 10em;
        line-height: 0.75em;
}
.container .ranks .item .position[data-v-1f0e9ad8] {
        font-size: 1.6em;
        position: absolute;
        top: 0;
        left: 0;
        color: #f41d48;
}
.container .ranks .item .data[data-v-1f0e9ad8] {
        line-height: 0.8em;
        font-size: 3.6em;
        position: absolute;
        bottom: 0;
        left: 0;
}
.container .gmap[data-v-1f0e9ad8] {
    position: absolute;
    bottom: -80px;
    width: 100%;
    right: 0;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}
.container .gmap.show[data-v-1f0e9ad8] {
      opacity: 1;
}
.container .gmap.exit[data-v-1f0e9ad8] {
      opacity: 0;
}
.container .slider[data-v-1f0e9ad8] {
    margin-right: 50px;
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    -webkit-transition-delay: 0.05s;
            transition-delay: 0.05s;
    opacity: 0;
}
.container .slider.show[data-v-1f0e9ad8] {
      -webkit-transition: opacity 0.3s ease-out, margin-right 0.6s cubic-bezier(0.75, 0, 0, 1.75);
      transition: opacity 0.3s ease-out, margin-right 0.6s cubic-bezier(0.75, 0, 0, 1.75);
      -webkit-transition-delay: 0.5s;
              transition-delay: 0.5s;
      margin-right: 0px;
      opacity: 1;
}
.container .slider.exit[data-v-1f0e9ad8] {
      -webkit-transition: opacity 0.3s ease-in, margin-right 0.3s ease-in;
      transition: opacity 0.3s ease-in, margin-right 0.3s ease-in;
      -webkit-transition-delay: 0.05s;
              transition-delay: 0.05s;
      margin-right: -30px;
      opacity: 0;
}
.container.portrait[data-v-1f0e9ad8] {
    text-align: left;
}
.container.portrait .ranks .item[data-v-1f0e9ad8] {
      left: 0px;
      right: 0;
      text-align: right;
}
.container.portrait .slider[data-v-1f0e9ad8] {
      margin-left: 50px;
      -webkit-transition: opacity 0.3s ease-out;
      transition: opacity 0.3s ease-out;
      -webkit-transition-delay: 0.05s;
              transition-delay: 0.05s;
      opacity: 0;
}
.container.portrait .slider.show[data-v-1f0e9ad8] {
        -webkit-transition: opacity 0.3s ease-out, margin-left 0.6s cubic-bezier(0.75, 0, 0, 1.75);
        transition: opacity 0.3s ease-out, margin-left 0.6s cubic-bezier(0.75, 0, 0, 1.75);
        -webkit-transition-delay: 0.5s;
                transition-delay: 0.5s;
        margin-left: 0px;
        opacity: 1;
}
.container.portrait .slider.exit[data-v-1f0e9ad8] {
        -webkit-transition: opacity 0.3s ease-in, margin-left 0.3s ease-in;
        transition: opacity 0.3s ease-in, margin-left 0.3s ease-in;
        -webkit-transition-delay: 0.05s;
                transition-delay: 0.05s;
        margin-left: -30px;
        opacity: 0;
}

.map[data-v-6c6ea073] {
  position: relative;
  display: block;
  width: auto;
  height: 70%;
  left: 40px;
}
.map.portrait[data-v-6c6ea073] {
    left: 0px;
}
.map canvas[data-v-6c6ea073] {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    height: 60%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    color: #ffffff;
}

.container[data-v-2b007fbc] {
  text-align: right;
}
.container .title[data-v-2b007fbc] {
    font-size: 1.6em;
    color: #f41d48;
}
.container .title.big[data-v-2b007fbc] {
      font-size: 4.5em;
}
.container .row[data-v-2b007fbc] {
    padding: 20px 0 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    -webkit-transition: border-bottom 0.3s ease-out;
    transition: border-bottom 0.3s ease-out;
}
.container .row.show[data-v-2b007fbc] {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.container .row[data-v-2b007fbc]:nth-of-type(1) {
      padding-top: 0px;
      border-bottom: 0px solid;
}
.container .row[data-v-2b007fbc]:nth-of-type(4) {
      border-bottom: 0px solid;
}
.container .name[data-v-2b007fbc] {
    text-transform: uppercase;
    font-size: 5em;
    color: #f41d48;
    line-height: 1em;
}
.container .name.big[data-v-2b007fbc] {
      font-size: 9.5em;
}
.container .name span[data-v-2b007fbc] {
      color: #fff;
      margin-right: 10px;
}
.container .number[data-v-2b007fbc] {
    font-size: 1.6em;
}
.container .number.big[data-v-2b007fbc] {
      font-size: 3.6em;
}
.container .slider[data-v-2b007fbc] {
    margin-right: 50px;
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    -webkit-transition-delay: 0.05s;
            transition-delay: 0.05s;
    opacity: 0;
}
.container .slider.show[data-v-2b007fbc] {
      -webkit-transition: opacity 0.3s ease-out, margin-right 0.6s cubic-bezier(0.75, 0, 0, 1.75);
      transition: opacity 0.3s ease-out, margin-right 0.6s cubic-bezier(0.75, 0, 0, 1.75);
      -webkit-transition-delay: 0.5s;
              transition-delay: 0.5s;
      margin-right: 0px;
      opacity: 1;
}
.container .slider.exit[data-v-2b007fbc] {
      -webkit-transition: opacity 0.3s ease-in, margin-right 0.3s ease-in;
      transition: opacity 0.3s ease-in, margin-right 0.3s ease-in;
      -webkit-transition-delay: 0.05s;
              transition-delay: 0.05s;
      margin-right: -30px;
      opacity: 0;
}
.container.portrait[data-v-2b007fbc] {
    text-align: left;
}
.container.portrait .row[data-v-2b007fbc] {
      padding: 10px 0 10px;
}
.container.portrait .row[data-v-2b007fbc]:nth-of-type(1) {
        padding-top: 0px;
}
.container.portrait .name[data-v-2b007fbc] {
      font-size: 4em;
}
.container.portrait .name.big[data-v-2b007fbc] {
        font-size: 7em;
}
.container.portrait .slider[data-v-2b007fbc] {
      margin-left: 50px;
      -webkit-transition: opacity 0.3s ease-out;
      transition: opacity 0.3s ease-out;
      -webkit-transition-delay: 0.05s;
              transition-delay: 0.05s;
      opacity: 0;
}
.container.portrait .slider.show[data-v-2b007fbc] {
        -webkit-transition: opacity 0.3s ease-out, margin-left 0.6s cubic-bezier(0.75, 0, 0, 1.75);
        transition: opacity 0.3s ease-out, margin-left 0.6s cubic-bezier(0.75, 0, 0, 1.75);
        -webkit-transition-delay: 0.5s;
                transition-delay: 0.5s;
        margin-left: 0px;
        opacity: 1;
}
.container.portrait .slider.exit[data-v-2b007fbc] {
        -webkit-transition: opacity 0.3s ease-in, margin-left 0.3s ease-in;
        transition: opacity 0.3s ease-in, margin-left 0.3s ease-in;
        -webkit-transition-delay: 0.05s;
                transition-delay: 0.05s;
        margin-left: -30px;
        opacity: 0;
}
.container .row:nth-of-type(1) .show[data-v-2b007fbc]:nth-of-type(1) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms;
}
.container .row:nth-of-type(1) .show[data-v-2b007fbc]:nth-of-type(2) {
    -webkit-transition-delay: 200ms;
            transition-delay: 200ms;
}
.container .row:nth-of-type(2) .show[data-v-2b007fbc]:nth-of-type(1) {
    -webkit-transition-delay: 250ms;
            transition-delay: 250ms;
}
.container .row:nth-of-type(2) .show[data-v-2b007fbc]:nth-of-type(2) {
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms;
}
.container .row:nth-of-type(3) .show[data-v-2b007fbc]:nth-of-type(1) {
    -webkit-transition-delay: 350ms;
            transition-delay: 350ms;
}
.container .row:nth-of-type(3) .show[data-v-2b007fbc]:nth-of-type(2) {
    -webkit-transition-delay: 400ms;
            transition-delay: 400ms;
}
.container .row:nth-of-type(4) .show[data-v-2b007fbc]:nth-of-type(1) {
    -webkit-transition-delay: 450ms;
            transition-delay: 450ms;
}
.container .row:nth-of-type(4) .show[data-v-2b007fbc]:nth-of-type(2) {
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms;
}
